import React from 'react';
import styled from 'styled-components';
import configJson from '../../../content/default-config.json';
import PrismCode from '../PrismCode.js';

const Wrapper = styled.div``;

const DefaultConfig = () => {
  return (
    <Wrapper className="my-subscriptions-widget-config-docs">
      <PrismCode
        code={JSON.stringify(configJson, undefined, 2)}
        language="json"
      />
    </Wrapper>
  );
};

export default DefaultConfig;
