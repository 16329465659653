import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: fill;
  justify-content: space-between;
  .sp-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 1rem;
    margin: 1rem 0;
    border: 1px solid #d8d8c2;
    background-color: #F6F6F1;
    text-align: center;
    font-size: larger;
    p {
      margin: 2rem 0 1rem;
      img {
        margin: -2rem 0 0;
        padding: 0 1rem;
      }
    }
    a {
      color: #24b286;
    }
    a:hover {
      color: #28c796;
    }
  }
  @media only screen and (min-width: 769px) and (max-width: 1087px) {
    .sp-card {
      width: 48%;
    }
  }
  @media only screen and (min-width: 1088px) {
    .sp-card {
      width: 30%;
    }
  }
`;

const Cards = ({ children }) => (
  <Wrapper className="sp-cards">{children}</Wrapper>
);

export default Cards;
