import React from 'react';
import DataModelTable from '../DataModelTable';

const schemaJson = [
  {
    fieldName: '_meta',
    dataSchema: [
      {
        name: 'changed_by',
        type: 'object',
        description:
          'Details about the customer, user or other agent who is making the change represented by the associated API request.',
      },
      {
        name: 'order_details',
        type: 'object',
        description:
          'Details about an eCommerce order which is associated with subscription(s) referenced in this API request.<br/><br/>This field is used to pass order details to Subscribe Pro when a customer signs up for a new subscription item via the eCommerce checkout on a connected eCommerce store.<br/><br/><strong>See:</strong> <a href="/technical/rest-api/data-definitions/order-details/">Order Details Data Definition</a>',
      },
    ],
  },
  {
    fieldName: '_meta.changed_by.customer',
    dataSchema: [
      {
        name: '_meta.changed_by.customer.customer_id',
        type: 'string',
        description: 'Subscribe Pro customer ID.',
      },
      {
        name: '_meta.changed_by.customer.email',
        type: 'string',
        description: 'Customer email address',
      },
      {
        name: '_meta.changed_by.customer.full_name',
        type: 'string',
        description: 'Customer full name.',
      },
    ],
  },
  {
    fieldName: '_meta.changed_by.admin',
    dataSchema: [
      {
        name: '_meta.changed_by.admin.user_id',
        type: 'string',
        description:
          "User ID of the user who is making this change. This may be a unique user ID for the the admin user in the merchant's eCommerce platform or another external system.",
      },
      {
        name: '_meta.changed_by.admin.email',
        type: 'string',
        description: "Admin user's email address.",
      },
      {
        name: '_meta.changed_by.admin.full_name',
        type: 'string',
        description: "Admin user's full name.",
      },
    ],
  },
  {
    fieldName: '_meta.order_details',
    dataSchema: [
      {
        name: 'customer',
        type: 'object',
        description:
          'Details about the customer who is making the change represented by this request.',
      },
    ],
  },
];

const MetadataFieldsSchema = ({ 'metadata-field': fieldName }) => {
  const fieldSchema = schemaJson.find(el => el.fieldName === fieldName);
  const dataSchema =
    fieldSchema && fieldSchema.dataSchema ? fieldSchema.dataSchema : [];

  return <DataModelTable properties={dataSchema} />;
};

export default MetadataFieldsSchema;
