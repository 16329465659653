import React, { useEffect } from 'react';
import Prism from 'prismjs';

const PrismCode = ({ code, language }) => {
  useEffect(() => Prism.highlightAll(), []);
  return (
    <pre className="sp-prism-code">
      <code className={`language-${language}`}>{code}</code>
    </pre>
  );
};

export default PrismCode;
