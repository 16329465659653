import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

const Wrapper = styled.div`
  .property-name {
    font-family: 'DM Mono', monospace;
  }
  .property-type {
    margin-left: 0.5rem;
    color: #ccc;
  }
  .optionality {
    font-size: 0.9rem;
  }
  .optionality.required {
    color: #d8d8d8;
  }
  .optionality.optional {
    color: #d8d8d8;
  }
`;

const Property = ({ property }) => {
  const optionality = property.required ? 'required' : 'optional';

  return (
    <div>
      <strong className="property-name">{property.name}</strong>
      <span className="property-type">{property.type}</span>
      <br />
      <span className={`optionality ${optionality}`}>{optionality}</span>
      <p>{property.description}</p>
      <hr />
    </div>
  );
};

const Parameters = ({ pathSpec }) => {
  // Parse parameters
  const queryParams = pathSpec.parameters.filter(o => o.in === 'query');
  const bodyParams = pathSpec.parameters.filter(o => o.in === 'body');

  return (
    <Wrapper className="parameters">
      {queryParams.length > 0 && (
        <div className="query">
          <h4>Query Parameters</h4>
          {queryParams.map((parameter, index) => (
            <Property property={parameter} key={index} />
          ))}
        </div>
      )}
      {bodyParams.length > 0 && (
        <div className="body">
          <h4>POST Body</h4>
          {bodyParams.map((parameter, index) => (
            <div key={index}>
              {_.has(parameter, 'definition.properties[0]') &&
                parameter.definition.properties.map((property, index) => (
                  <Property property={property} key={index} />
                ))}
            </div>
          ))}
        </div>
      )}
    </Wrapper>
  );
};

export default Parameters;
