import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 2rem 3rem;
`;

const DocsImage = ({
  src,
  title,
  shadow = false,
  'rounded-corners': roundedCorners = false,
  'corner-radius': cornerRadius = '4px',
  'max-width': maxWidth = null,
}) => {
  const imageStyles = {
    'box-shadow': shadow === 'true' ? '0px 6px 14px 0px rgba(0, 0, 0, 0.30)' : 'none',
    'border-radius': roundedCorners === 'true' ? cornerRadius : '0',
  };
  if (maxWidth !== null) {
    imageStyles['max-width'] = maxWidth;
  }

  return (
    <Wrapper className="sp-docs-image">
      <img style={imageStyles} src={src} alt={title} title={title} />
    </Wrapper>
  );
};

export default DocsImage;
