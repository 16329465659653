import React from 'react';
import { Link } from 'gatsby';
import { navigate, Location } from '@reach/router';
import isAbsoluteUrl from 'is-absolute-url';
import ExternalLink from './ExternalLink';

const addTrailingSlash = url => url.replace(/\/?$/, '/');

const MarkdownLink = props => {
  // Pull incoming className from "class" prop
  var { class: className, href, children, ...elemProps } = props;

  if (isAbsoluteUrl(href)) {
    // External Links
    return (
      <ExternalLink className={className} href={href} {...elemProps}>
        {children}
      </ExternalLink>
    );
  } else if (href.startsWith('#')) {
    // Anchor links in markdown content
    return (
      <Location>
        {({ location }) => (
          <a
            className={className}
            href={location.pathname + href}
            {...elemProps}
            onClick={e => {
              e.preventDefault();
              navigate(href);
            }}
          >
            {children}
          </a>
        )}
      </Location>
    );
  } else if (href.endsWith('.pdf')) {
    // File download links
    return (
      <ExternalLink className={className} href={href} {...elemProps}>
        {children}
      </ExternalLink>
    );
  } else {
    // Normal internal links
    return (
      <Link className={className} to={addTrailingSlash(href)} {...elemProps}>
        {children}
      </Link>
    );
  }
};

export default MarkdownLink;
