import React from 'react';
import classNames from 'classnames';

const H3 = props => {
  var { className, ...elemProps } = props;

  return (
    <h3 className={classNames(className, 'title', 'is-3')} {...elemProps}>
      {props.children}
    </h3>
  );
};

export default H3;
