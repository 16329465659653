import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Request from './Request';
import Parameters from './Parameters';
import Response from './Response';
import Title from './Title';

const Wrapper = styled.div`
  margin-bottom: 4rem;

  /* tabs */
  .tabs ul {
    /* fix weird issue */
    align-items: flex-end;
    margin: 0;
    padding: 0;
  }
`;

const isBrowser = typeof window !== `undefined`;

const buildTabId = (pathName, tabName) =>
  pathName
    .toLowerCase()
    .replace('/', '')
    .replace(/[/.{}]+/g, '-') +
  '-' +
  tabName.toLowerCase();

const Tab = ({ pathName, tabName, currentTab, onClick, children }) => {
  const tabId = buildTabId(pathName, tabName);
  return (
    <li
      id={tabId}
      className={currentTab === tabName ? 'is-active' : ''}
      // onClick={e => {
      //   e.preventDefault();
      //   onClick(tabName);
      // }}
    >
      <a href={`#${tabId}`}>{children}</a>
    </li>
  );
};

class Endpoint extends React.Component {
  constructor(props) {
    super(props);

    var initialTab = 'request';
    if (isBrowser) {
      const curLocationHref = window.location.href;
      if (
        curLocationHref.endsWith(buildTabId(this.props.pathName, 'response'))
      ) {
        initialTab = 'response';
      } else if (
        curLocationHref.endsWith(buildTabId(this.props.pathName, 'parameters'))
      ) {
        initialTab = 'parameters';
      }
    }

    this.state = {
      currentTab: initialTab,
    };
  }

  changeTab = tabName => {
    this.setState({ currentTab: tabName });
    if (isBrowser) {
      window.history.pushState(
        {},
        '',
        '#' + buildTabId(this.props.pathName, tabName)
      );
    }
  };

  render() {
    const currentTab = this.state.currentTab;
    const pathSpec = this.props.pathSpec;
    const pathSpecSuccessResponse = pathSpec.successResponse;

    return (
      <Wrapper className="endpoint">
        {!this.props.hideTitle && <Title path={this.props.pathName} />}

        <div className="tabs">
          <ul>
            <Tab
              pathName={this.props.pathName}
              tabName="request"
              currentTab={currentTab}
              onClick={this.changeTab}
            >
              Request
            </Tab>
            <Tab
              pathName={this.props.pathName}
              tabName="parameters"
              currentTab={currentTab}
              onClick={this.changeTab}
            >
              Parameters
            </Tab>
            <Tab
              pathName={this.props.pathName}
              tabName="response"
              currentTab={currentTab}
              onClick={this.changeTab}
            >
              Response
            </Tab>
          </ul>
        </div>

        <div
          className={
            'tab-content' + (currentTab === 'request' ? '' : ' is-hidden')
          }
        >
          <Request
            verb={pathSpec.verb}
            fullPath={pathSpec.fullPath}
            exampleBody={pathSpec.example}
          />
        </div>

        <div
          className={
            'tab-content' + (currentTab === 'parameters' ? '' : ' is-hidden')
          }
        >
          <Parameters pathSpec={pathSpec} />
        </div>

        <div
          className={
            'tab-content' + (currentTab === 'response' ? '' : ' is-hidden')
          }
        >
          <Response
            responseStatus={pathSpecSuccessResponse.statusCode}
            description={pathSpecSuccessResponse.description}
            exampleResponse={pathSpecSuccessResponse.example}
          />
        </div>
      </Wrapper>
    );
  }
}

Endpoint.propTypes = {
  hideTitle: PropTypes.bool,
};

Endpoint.defaultProps = {
  hideTitle: false,
};

export default Endpoint;
