import React from 'react';
import classNames from 'classnames';

const H1 = props => {
  var { className, ...elemProps } = props;

  return (
    <>
      <h1
        className={classNames(
          className,
          'title',
          'is-1',
          'is-family-secondary'
        )}
        {...elemProps}
      >
        {props.children}
      </h1>
      <hr />
    </>
  );
};

export default H1;
