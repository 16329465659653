import React from 'react';
import classNames from 'classnames';

const H6 = props => {
  var { className, ...elemProps } = props;

  return (
    <h6 className={classNames(className, 'title', 'is-6')} {...elemProps}>
      {props.children}
    </h6>
  );
};

export default H6;
