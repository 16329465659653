import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

var linkifyHtml = require('linkifyjs/html');

const Wrapper = styled.div`
  .table {
    td:first-child {
      width: 30%;
    }
    td,
    th {
      word-wrap: break-word;
    }
    th span {
      font-size: 14px;
    }
  }
  .property-name-cell {
    .property-name {
      display: block;
      font-weight: 600;
      font-family: ${props => props.theme.familyCode};
      font-size: smaller;
      .is-required {
        font-family: ${props => props.theme.familySansSerif};
        color: ${props => props.theme.redError};
      }
    }
    .property-type {
      display: block;
      color: ${props => props.theme.grey};
      font-size: smaller;
    }
  }
  .property-details-cell {
    .property-detail:not(:first-child) {
      margin-top: 0.5rem;
    }
    .property-title {
      font-weight: 600;
    }
    .default-value {
      font-family: ${props => props.theme.familyCode};
      font-size: 90%;
      .string-value {
        color: ${props => props.theme.spLightOrange};
      }
      .number-value,
      .bool-value {
        color: ${props => props.theme.spLightBlue};
      }
    }
  }
`;

const addTrailingSlash = url => url.replace(/\/?$/, '/');

const renderRow = ({
  name,
  type,
  typeLink,
  defaultValue,
  defaultValueType = 'string',
  description,
  indent = 0,
  title,
  isRequired = false,
}) => {
  const indentStyle = { marginLeft: (indent * 24).toString() + 'px' };

  var typeContent;
  if (typeLink) {
    typeContent = <Link to={addTrailingSlash(typeLink)}>{type}</Link>;
  } else {
    typeContent = type;
  }

  return (
    <tr key={name}>
      <td className="property-name-cell">
        <span className="property-name" style={indentStyle}>
          {name} {isRequired && <span className="is-required">(required)</span>}
        </span>
        <span className="property-type" style={indentStyle}>
          {typeContent}
        </span>
      </td>
      <td className="property-details-cell">
        {title && (
          <div className="property-detail">
            <span className="property-title">{title}</span>
          </div>
        )}
        {defaultValue && (
          <div className="property-detail">
            <strong>Default:</strong>&nbsp;
            <span className="default-value">
              {
                {
                  string: defaultValue && (
                    <span className="string-value">'{defaultValue}'</span>
                  ),
                  number: <span className="number-value">{defaultValue}</span>,
                  bool: <span className="bool-value">{defaultValue}</span>,
                }[defaultValueType]
              }
            </span>
          </div>
        )}
        {description && (
          <div className="property-detail">
            <span
              className="field-desc"
              dangerouslySetInnerHTML={{ __html: linkifyHtml(description) }}
            />
            <br />
          </div>
        )}
      </td>
    </tr>
  );
};

const exampleProperties = [
  {
    name: 'adjusted_tax',
    type: 'Decimal',
    defaultValue: '0.00',
    defaultValueType: 'number',
    description: 'The tax of the product item after adjustments applying.',
  },
  {
    name: 'adjusted_tax',
    type: 'string',
    defaultValue: '0.00',
    description: 'The tax of the product item after adjustments applying.',
    indent: 1,
  },
  {
    name: 'new_group',
    type: 'object',
    title: 'New Group',
    description: 'List of subscription items, each with an attached price.',
  },
  {
    name: 'magento_address_id',
    type: 'integer',
    description:
      "Unique ID of the sync'd customer address in the linked Magento instance.",
    isRequired: true,
  },
  {
    name: 'created',
    type: 'string <date-time>',
    description: 'Created at date/time stamp',
    isRequired: true,
  },
  {
    name: 'addressMode',
    type: 'string',
    defaultValue: 'both',
    description: `
      Determines which addresses on Cart are used for ordering.<br/>
      <br/>
      Available address modes:
      <ul>
      <li><code>'both'</code>  -  Both billingAddress and shippingAddress (on each Shipment) should be populated.</li>
      <li><code>'use_shipping_for_billing'</code>  -  Use the shippingAddress (from first Shipment) as billingAddress.</li>
      <li><code>'use_billing_for_shipping'</code>  -  Use the billingAddress as shippingAddress on all Shipments.</li>
      <li><code>'billing_only'</code>  -  Only the billingAddress is used on this order.</li>
      <li><code>'shipping_only'</code>  -  Only the shippingAddress is used on this order.</li>
      </ul>
    `,
    isRequired: true,
  },
  {
    name: 'billingAddress',
    type: 'BillingAddress',
    typeLink: '/api/v2/models/billing-address',
    description: 'Billing address',
    isRequired: false,
  },
];

const DataModelTable = ({
  properties = exampleProperties,
  headers = { name: 'Property', details: 'Details' },
}) => (
  <Wrapper className="data-model-docs-table">
    <table className="table is-hoverable">
      {headers && (
        <thead>
          <tr>
            <th>
              <span>{headers.name}</span>
            </th>
            <th>
              <span>{headers.details}</span>
            </th>
          </tr>
        </thead>
      )}
      <tbody>{properties.map(property => renderRow(property))}</tbody>
    </table>
  </Wrapper>
);

export default DataModelTable;
