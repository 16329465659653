import React from 'react';
import transform from 'lodash/transform';
import reduce from 'lodash/reduce';

import docsJson from '../../../content/config-docs.json';

import DataModelTable from '../DataModelTable';

const flattenDocs = (configDocs, maxDepth, basePath = null) => {
  const iteratee = basePath => (result, value, key) => {
    basePath = basePath ? basePath : '';
    const nestedPath = basePath.length ? `${basePath}.${key}` : key;
    const depth = 1 + (nestedPath.match(/\./g) || []).length;

    // console.log('Base path: ' + basePath);
    // console.log('Nested path: ' + nestedPath);
    // console.log('Depth: ' + depth);

    // Recursively parse groups
    if (value && typeof value === 'object' && value.hasOwnProperty('_group')) {
      // Check maxDepth not exceeded
      if (maxDepth === 0 || depth < maxDepth) {
        // Check group not hidden
        if (
          !value['_group'].hasOwnProperty('_hidden') ||
          value['_group']._hidden === false
        ) {
          // Group details
          const groupDetails = {
            name: nestedPath,
            description: value['_group']._desc ? value['_group']._desc : null,
            title: value['_group']._title ? value['_group']._title : null,
          };
          result.push(groupDetails);
          // Group contents recursively
          transform(value, iteratee(nestedPath), []).forEach(el =>
            result.push(el)
          );
        }
      }
    }
    // Ignore group details here, catch them above when on group key
    else if (key === '_group') {
    }
    // Otherwise, regular config key, translate details
    else {
      const keyDetails = {
        name: nestedPath,
        description: value._desc ? value._desc : null,
        isRequired: value._required ? value._required : false,
        defaultValue: value.hasOwnProperty('_default') ? value._default : null,
      };
      if (value._type === 'int') {
        keyDetails.type = 'int';
        keyDetails.defaultValueType = 'number';
        keyDetails.defaultValue =  value.hasOwnProperty('_default') ? Number.parseInt(value._default).toString() : null;
      }
      else if (value._type === 'bool') {
        keyDetails.type = 'bool';
        keyDetails.defaultValueType = 'number';
        keyDetails.defaultValue = value._default ? 'true' : 'false';
      }
      result.push(keyDetails);
    }
  };

  return transform(configDocs, iteratee(basePath), []);
};

const parseStartPath = startPath =>
  startPath.split('.').filter(el => el !== undefined && el.length > 0);

const docsAtStartPath = (startPath, docsJson) => {
  const keys = parseStartPath(startPath);
  return reduce(
    keys,
    (result, value, key) => {
      return result[value];
    },
    docsJson
  );
};

const WidgetConfigDocs = ({
  'start-path': startPath = '',
  'max-depth': maxDepth = 0,
}) => {
  const flatDocs = flattenDocs(
    docsAtStartPath(startPath, docsJson),
    maxDepth,
    startPath
  );
  return <DataModelTable properties={flatDocs} />;
};

export default WidgetConfigDocs;
