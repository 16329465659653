import React from 'react';
import classNames from 'classnames';

const H2 = props => {
  var { className, ...elemProps } = props;

  return (
    <h2
      className={classNames(className, 'title', 'is-2', 'is-family-secondary')}
      {...elemProps}
    >
      {props.children}
    </h2>
  );
};

export default H2;
