import React from 'react';
import DataModelTable from '../DataModelTable';

const schemaJson = [
  {
    eventType: 'customer.subscribed',
    dataSchema: [
      {
        name: 'customer_id',
        type: 'int',
        description:
          'Subscribe Pro Customer ID of the customer record associated with this event.',
      },
      {
        name: 'customer',
        type: 'Customer',
        typeLink:
          'https://api.subscribepro.com/docs/rest#get--services-v2-customers-{id}.{_format}',
        description:
          'Subscribe Pro Customer record associated with this event.',
      },
    ],
  },
  {
    eventType: 'customer.cancelled',
    dataSchema: [
      {
        name: 'customer_id',
        type: 'int',
        description:
          'Subscribe Pro Customer ID of the customer record associated with this event.',
      },
      {
        name: 'customer',
        type: 'Customer',
        typeLink:
          'https://api.subscribepro.com/docs/rest#get--services-v2-customers-{id}.{_format}',
        description:
          'Subscribe Pro Customer record associated with this event.',
      },
    ],
  },
  {
    eventType: 'payment_profile.created',
    dataSchema: [
      {
        name: 'customer_id',
        type: 'int',
        description:
          'Subscribe Pro Customer ID of the customer record associated with this event.',
      },
      {
        name: 'payment_profile_id',
        type: 'int',
        description:
          'Subscribe Pro Payment Profile ID of the payment profile record associated with this event.',
      },
      {
        name: 'customer',
        type: 'Customer',
        typeLink:
          'https://api.subscribepro.com/docs/rest#get--services-v2-customers-{id}.{_format}',
        description:
          'Subscribe Pro Customer record associated with this event.',
      },
      {
        name: 'payment_profile',
        type: 'PaymentProfile',
        typeLink:
          'https://api.subscribepro.com/docs/rest#get--services-v2-vault-paymentprofiles-{id}.{_format}',
        description:
          'Subscribe Pro Payment Profile record associated with this event.',
      },
    ],
  },
  {
    eventType: 'payment_profile.updated',
    dataSchema: [
      {
        name: 'customer_id',
        type: 'int',
        description:
          'Subscribe Pro Customer ID of the customer record associated with this event.',
      },
      {
        name: 'payment_profile_id',
        type: 'int',
        description:
          'Subscribe Pro Payment Profile ID of the payment profile record associated with this event.',
      },
      {
        name: 'customer',
        type: 'Customer',
        typeLink:
          'https://api.subscribepro.com/docs/rest#get--services-v2-customers-{id}.{_format}',
        description:
          'Subscribe Pro Customer record associated with this event.',
      },
      {
        name: 'payment_profile',
        type: 'PaymentProfile',
        typeLink:
          'https://api.subscribepro.com/docs/rest#get--services-v2-vault-paymentprofiles-{id}.{_format}',
        description:
          'Subscribe Pro Payment Profile record associated with this event.',
      },
    ],
  },
  {
    eventType: 'payment_profile.redacted',
    dataSchema: [
      {
        name: 'customer_id',
        type: 'int',
        description:
          'Subscribe Pro Customer ID of the customer record associated with this event.',
      },
      {
        name: 'payment_profile_id',
        type: 'int',
        description:
          'Subscribe Pro Payment Profile ID of the payment profile record associated with this event.',
      },
      {
        name: 'customer',
        type: 'Customer',
        typeLink:
          'https://api.subscribepro.com/docs/rest#get--services-v2-customers-{id}.{_format}',
        description:
          'Subscribe Pro Customer record associated with this event.',
      },
      {
        name: 'payment_profile',
        type: 'PaymentProfile',
        typeLink:
          'https://api.subscribepro.com/docs/rest#get--services-v2-vault-paymentprofiles-{id}.{_format}',
        description:
          'Subscribe Pro Payment Profile record associated with this event.',
      },
    ],
  },
  {
    eventType: 'subscription.created',
    dataSchema: [
      {
        name: 'subscription_id',
        type: 'int',
        description:
          'Subscribe Pro Subscription ID of the subscription record associated with this event.',
      },
      {
        name: 'customer',
        type: 'Customer',
        typeLink:
          'https://api.subscribepro.com/docs/rest#get--services-v2-customers-{id}.{_format}',
        description:
          'Subscribe Pro Customer record associated with this event.',
      },
      {
        name: 'subscription',
        type: 'Subscription',
        typeLink:
          'https://api.subscribepro.com/docs/rest#get--services-v2-subscriptions-{id}.{_format}',
        description:
          'Subscribe Pro Subscription record associated with this event.',
      },
    ],
  },
  {
    eventType: 'subscription.updated',
    dataSchema: [
      {
        name: 'subscription_id',
        type: 'int',
        description:
          'Subscribe Pro Subscription ID of the subscription record associated with this event.',
      },
      {
        name: 'customer',
        type: 'Customer',
        typeLink:
          'https://api.subscribepro.com/docs/rest#get--services-v2-customers-{id}.{_format}',
        description:
          'Subscribe Pro Customer record associated with this event.',
      },
      {
        name: 'subscription',
        type: 'Subscription',
        typeLink:
          'https://api.subscribepro.com/docs/rest#get--services-v2-subscriptions-{id}.{_format}',
        description:
          'Subscribe Pro Subscription record associated with this event.',
      },
    ],
  },
  {
    eventType: 'subscription.cancelled',
    dataSchema: [
      {
        name: 'subscription_id',
        type: 'int',
        description:
          'Subscribe Pro Subscription ID of the subscription record associated with this event.',
      },
      {
        name: 'customer',
        type: 'Customer',
        typeLink:
          'https://api.subscribepro.com/docs/rest#get--services-v2-customers-{id}.{_format}',
        description:
          'Subscribe Pro Customer record associated with this event.',
      },
      {
        name: 'subscription',
        type: 'Subscription',
        typeLink:
          'https://api.subscribepro.com/docs/rest#get--services-v2-subscriptions-{id}.{_format}',
        description:
          'Subscribe Pro Subscription record associated with this event.',
      },
    ],
  },
  {
    eventType: 'subscription.status_change',
    dataSchema: [
      {
        name: 'subscription_id',
        type: 'int',
        description:
          'Subscribe Pro Subscription ID of the subscription record associated with this event.',
      },
      {
        name: 'old_status',
        type: 'string',
        description:
          'Subscription status code from before the status change.<br/><strong>For example:</strong> <code class="language-javascript">\'active\'</code>',
      },
      {
        name: 'new_status',
        type: 'string',
        description:
          'Subscription status code from after the status change.<br/><strong>For example:</strong> <code class="language-javascript">\'paused\'</code>',
      },
      {
        name: 'customer',
        type: 'Customer',
        typeLink:
          'https://api.subscribepro.com/docs/rest#get--services-v2-customers-{id}.{_format}',
        description:
          'Subscribe Pro Customer record associated with this event.',
      },
      {
        name: 'subscription',
        type: 'Subscription',
        typeLink:
          'https://api.subscribepro.com/docs/rest#get--services-v2-subscriptions-{id}.{_format}',
        description:
          'Subscribe Pro Subscription record associated with this event.',
      },
    ],
  },
  {
    eventType: 'subscription.trial.converted',
    dataSchema: [
      {
        name: 'subscription_id',
        type: 'int',
        description:
          'Subscribe Pro Subscription ID of the subscription record associated with this event.',
      },
      {
        name: 'customer',
        type: 'Customer',
        typeLink:
          'https://api.subscribepro.com/docs/rest#get--services-v2-customers-{id}.{_format}',
        description:
          'Subscribe Pro Customer record associated with this event.',
      },
      {
        name: 'subscription',
        type: 'Subscription',
        typeLink:
          'https://api.subscribepro.com/docs/rest#get--services-v2-subscriptions-{id}.{_format}',
        description:
          'Subscribe Pro Subscription record associated with this event.',
      },
    ],
  },
  {
    eventType: 'subscription.email',
    dataSchema: [
      {
        name: 'subscription_id',
        type: 'int',
        description:
          'Subscribe Pro Subscription ID of the subscription record associated with this event.',
      },
      {
        name: 'email_template_type',
        type: 'string',
        description:
          'Email template type code for the type of email that was triggered. For detailed documentation of the email template type codes, see: <a href="/users/managing-transactional-emails/template-format/types/">Customer-Facing Transactional Email Templates</a>',
      },
      {
        name: 'customer',
        type: 'Customer',
        typeLink:
          'https://api.subscribepro.com/docs/rest#get--services-v2-customers-{id}.{_format}',
        description:
          'Subscribe Pro Customer record associated with this event.',
      },
      {
        name: 'subscription',
        type: 'Subscription',
        typeLink:
          'https://api.subscribepro.com/docs/rest#get--services-v2-subscriptions-{id}.{_format}',
        description:
          'Subscribe Pro Subscription record associated with this event.',
      },
    ],
  },
  {
    eventType: 'group_subscription.created',
    dataSchema: [
      {
        name: 'group_subscription_id',
        type: 'int',
        description:
          'Subscribe Pro Group Subscription ID of the subscription record associated with this event.',
      },
      {
        name: 'customer',
        type: 'Customer',
        typeLink:
          'https://api.subscribepro.com/docs/rest#get--services-v2-customers-{id}.{_format}',
        description:
          'Subscribe Pro Customer record associated with this event.',
      },
      {
        name: 'group_subscription',
        type: 'Group Subscription',
        typeLink:
          'https://api.subscribepro.com/docs/rest#get--services-v2-group-subscriptions-{id}.{_format}',
        description:
          'Subscribe Pro Subscription record associated with this event.',
      },
    ],
  },
  {
    eventType: 'group_subscription.updated',
    dataSchema: [
      {
        name: 'group_subscription_id',
        type: 'int',
        description:
          'Subscribe Pro Group Subscription ID of the subscription record associated with this event.',
      },
      {
        name: 'customer',
        type: 'Customer',
        typeLink:
          'https://api.subscribepro.com/docs/rest#get--services-v2-customers-{id}.{_format}',
        description:
          'Subscribe Pro Customer record associated with this event.',
      },
      {
        name: 'group_subscription',
        type: 'Group Subscription',
        typeLink:
          'https://api.subscribepro.com/docs/rest#get--services-v2-group-subscriptions-{id}.{_format}',
        description:
          'Subscribe Pro Subscription record associated with this event.',
      },
    ],
  },
  {
    eventType: 'payment_transaction.updated',
    dataSchema: [
      {
        name: 'transaction_id',
        type: 'int',
        description:
          'Subscribe Pro Transaction ID of the payment transaction record which was updated.',
      },
      {
        name: 'customer_id',
        type: 'int',
        description:
          'Subscribe Pro Customer ID of the customer record associated with this event.',
      },
      {
        name: 'payment_profile_id',
        type: 'int',
        description:
          'Subscribe Pro Payment Profile ID of the payment profile record associated with this event.',
      },
      {
        name: 'transaction',
        type: 'Transaction',
        typeLink:
          'https://api.subscribepro.com/docs/rest#get--services-v1-vault-transactions-{id}.{_format}',
        description:
          'Subscribe Pro Transaction record associated with this event.',
      },
      {
        name: 'customer',
        type: 'Customer',
        typeLink:
          'https://api.subscribepro.com/docs/rest#get--services-v2-customers-{id}.{_format}',
        description:
          'Subscribe Pro Customer record associated with this event.',
      },
      {
        name: 'payment_profile',
        type: 'PaymentProfile',
        typeLink:
          'https://api.subscribepro.com/docs/rest#get--services-v2-vault-paymentprofiles-{id}.{_format}',
        description:
          'Subscribe Pro Payment Profile record associated with this event.',
      },
    ],
  },
];

const WebhookEventSchema = ({ 'event-type': eventType }) => {
  const eventSchema = schemaJson.find((el) => el.eventType === eventType);
  const dataSchema =
    eventSchema && eventSchema.dataSchema ? eventSchema.dataSchema : [];

  return <DataModelTable properties={dataSchema} />;
};

export default WebhookEventSchema;
