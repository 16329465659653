import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const HostedSubscriptionsVersion = () => {
  const data = useStaticQuery(graphql`
    query WidgetVersion {
      hostedSubscriptions {
        version
        widgetUrl
      }
    }
  `);
  return (
    <ul>
      <li>
        <strong>Current Version:</strong> {data.hostedSubscriptions.version}
      </li>
      <li>
        <strong>Current Script URL:</strong>{' '}
        <a href={data.hostedSubscriptions.widgetUrl}>
          {data.hostedSubscriptions.widgetUrl}
        </a>
      </li>
    </ul>
  );
};

export default HostedSubscriptionsVersion;
