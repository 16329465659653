import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.p`
  font-size: 1rem;
  margin-bottom: 1.2rem;

  .endpoint-spec,
  .summary {
    display: block;
  }

  .endpoint-spec {
    .verb {
      font-weight: bold;
      margin-right: 0.6rem;
    }
    .verb-post {
      color: #10a54a;
    }
    .verb-get {
      color: #0f6ab4;
    }
    .verb-put {
      color: #c5862b;
    }
    .verb-delete {
      color: #a41e22;
    }
    .path {
      color: #5e5a41;
    }
  }

  .summary {
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }
`;

const Title = ({ path }) => {
  const [fullPath, verb] = path.split('.');
  return (
    <Wrapper className="title">
      <span className="endpoint-spec">
        <span className={'verb verb-' + verb.toLowerCase()}>
          {verb.toUpperCase()}
        </span>
        <code className="path">{fullPath}</code>
      </span>
    </Wrapper>
  );
};

export default Title;
