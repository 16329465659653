import React from 'react';
import styled from 'styled-components';
import Prism from 'prismjs';
import 'prismjs/components/prism-json';

const Wrapper = styled.div`
  .title {
    margin-bottom: 1.5rem;
    .description {
      font-size: 1rem;
    }
    .status {
      color: #10a54a;
      font-size: 1.1rem;
    }
  }
`;

const Response = ({ responseStatus, description, exampleResponse }) => (
  <Wrapper className="response">
    <div className="title">
      <span className="status">
        <strong>{responseStatus}:</strong>
      </span>
      &nbsp;
      <span className="description">{description}</span>
    </div>

    {Object.keys(exampleResponse).length > 0 && (
      <pre className="language-json">
        <code
          dangerouslySetInnerHTML={{
            __html: Prism.highlight(
              JSON.stringify(exampleResponse, null, 2),
              Prism.languages.json,
              'json'
            ),
          }}
        />
      </pre>
    )}
  </Wrapper>
);

export default Response;
