import React from 'react';
import DataModelTable from '../DataModelTable';

const schemaJson = [
  {
    name: 'allowSomeFailedItems',
    type: 'bool',
    title: 'Allow Some Failed Items',
    defaultValue: 'false',
    defaultValueType: 'bool',
    description:
      'The Subscribe Pro environment where this order was triggered is configured to allow partial orders - order attempts where at least 1 subscription item failed to be ordered and at least 1 item was successfully ordered.',
  },
  {
    name: 'customerId',
    isRequired: true,
    type: 'string',
    title: 'Subscribe Pro Customer ID',
  },
  {
    name: 'customerEmail',
    isRequired: true,
    type: 'string',
    format: 'email',
    title: 'Customer Email',
  },
  {
    name: 'platformCustomerId',
    isRequired: true,
    type: 'string',
    title: 'eCommerce Platform Customer ID',
  },
  {
    name: 'salesOrderToken',
    type: 'string',
    title: 'Sales Order Token',
    description:
      'This is a unique token provided by Subscribe Pro when placing an order. It uniquely represents this request. Pass this token back to Subscribe Pro if it is necessary to make any API requests back to Subscribe Pro during the order placement process.',
  },
  {
    name: 'shippingMethodCode',
    type: 'string',
    title: 'Shipping Method Code',
    description:
      'Code that represents which shipping carrier and method should be used for this order.<br/><strong>Example:</strong> <code>"flat_rate"</code>, <code>"free_shipping"</code> OR <code>"ups_ground"</code>',
  },
  {
    name: 'currency',
    isRequired: true,
    type: 'string',
    title: 'Currency Code',
    description:
      '3 Digit ISO Currency Code.<br/><strong>Example:</strong> <code>"USD"</code>',
  },
  {
    name: 'payment',
    isRequired: true,
    type: 'object',
    title: 'Payment Details',
    description: 'Payment details specifying how this order should be paid.',
  },
  {
    name: 'payment.paymentToken',
    type: 'string',
    title: 'Payment Token',
    description:
      'Unique payment token that represents the payment method or payment profile which should be used to pay for this order.',
  },
  {
    name: 'payment.thirdPartyPaymentToken',
    type: 'string',
    title: 'Third Party Payment Token',
    description:
      'Unique payment token from third-party payment provider or gateway.',
  },
  {
    name: 'payment.paymentVaultCustomerToken',
    type: 'string',
    title: 'Payment Vault Customer Token',
    description:
      'Unique customer token from third-party payment provider or gateway',
  },
  {
    name: 'payment.paymentProfileId',
    type: 'string',
    title: 'Subscribe Pro Payment Profile ID',
    description:
      'Unique ID of a Subscribe Pro payment profile which should be used to pay for this order.',
  },
  {
    name: 'payment.paymentProfileType',
    type: 'string',
    title: 'Subscribe Pro Payment Profile Type',
    description:
      'Type of a Subscribe Pro payment profile which should be used to pay for this order. See: <a href="https://api.subscribepro.com/docs/rest#get--services-v2-vault-paymentprofiles-{id}.{_format}">GET /services/v2/vault/paymentprofiles/{id}</a>',
  },
  {
    name: 'payment.paymentMethodType',
    type: 'string',
    title: 'Subscribe Pro Payment Method Type',
    description:
      'Type of payment method for payment profile.<br/><strong>Example:</strong> <code>"creditcard"</code>',
  },
  {
    name: 'payment.creditcardType',
    type: 'string',
    title: 'Credit Card Type',
    description:
      '<strong>One of:</strong> <code>"visa"</code>, <code>"master"</code>, <code>"discover"</code>, <code>"american_express"</code>, etc',
  },
  {
    name: 'payment.creditcardMonth',
    type: 'string',
    title: 'Credit Card Expiration Month',
    description: '2 digit expiration date month.',
  },
  {
    name: 'payment.creditcardYear',
    type: 'string',
    title: 'Credit Card Expiration Year',
    description: '4 digit expiration date year.',
  },
  {
    name: 'payment.creditcardLastDigits',
    type: 'string',
    title: 'Credit Card Last Digits',
    description: 'Last 4 digits of card number that are safe to display.',
  },
  {
    name: 'couponCodes',
    type: 'array',
    title: 'Coupon Codes',
    description:
      'Array of coupon codes (string). These are listed in priority order and should be applied to the cart which is generated in this order.',
  },
  {
    name: 'billingAddress',
    isRequired: true,
    type: 'object',
    title: 'Billing Address',
    description: "Customer's billing address associated with the order.",
  },
  {
    name: 'billingAddress.firstName',
    type: 'string',
    isRequired: true,
    title: 'First Name',
  },
  {
    name: 'billingAddress.lastName',
    type: 'string',
    isRequired: true,
    title: 'Last Name',
  },
  {
    name: 'billingAddress.company',
    type: 'string',
    title: 'Company',
    description: 'Company name associated with the address',
  },
  {
    name: 'billingAddress.street1',
    type: 'string',
    title: 'Street Address Line 1',
  },
  {
    name: 'billingAddress.street2',
    type: 'string',
    title: 'Street Address Line 2',
  },
  {
    name: 'billingAddress.city',
    type: 'string',
    title: 'City',
  },
  {
    name: 'billingAddress.region',
    type: 'string',
    title: 'Region',
    description: 'Region, state or province',
  },
  {
    name: 'billingAddress.postcode',
    type: 'string',
    title: 'Postcode',
  },
  {
    name: 'billingAddress.country',
    type: 'string',
    title: 'Country',
    description: 'Country name, code or abbreviation',
  },
  {
    name: 'billingAddress.countryName',
    type: 'string',
    title: 'Country Name',
  },
  {
    name: 'billingAddress.phone',
    type: 'string',
    title: 'Phone',
    description: 'The phone number associated with the address.',
  },
  {
    name: 'shippingAddress',
    type: 'object',
    title: 'Shipping Address',
    description: "Customer's shipping address associated with the order.",
  },
  {
    name: 'shippingAddress.firstName',
    type: 'string',
    title: 'First Name',
  },
  {
    name: 'shippingAddress.lastName',
    type: 'string',
    title: 'Last Name',
  },
  {
    name: 'shippingAddress.company',
    type: 'string',
    title: 'Company',
    description: 'Company name associated with the address',
  },
  {
    name: 'shippingAddress.street1',
    type: 'string',
    title: 'Street Address Line 1',
  },
  {
    name: 'shippingAddress.street2',
    type: 'string',
    title: 'Street Address Line 2',
  },
  {
    name: 'shippingAddress.city',
    type: 'string',
    title: 'City',
  },
  {
    name: 'shippingAddress.region',
    type: 'string',
    title: 'Region',
    description: 'Region, state or province',
  },
  {
    name: 'shippingAddress.postcode',
    type: 'string',
    title: 'Postcode',
  },
  {
    name: 'shippingAddress.country',
    type: 'string',
    title: 'Country',
    description: 'Country name, code or abbreviation',
  },
  {
    name: 'shippingAddress.countryName',
    type: 'string',
    title: 'Country Name',
  },
  {
    name: 'shippingAddress.phone',
    type: 'string',
    title: 'Phone',
    description: 'The phone number associated with the address.',
  },
  {
    name: 'items',
    isRequired: true,
    type: 'array',
    title: 'Items',
    description: 'Array of order line items for this order.',
  },
  {
    name: 'items[].productSku',
    isRequired: true,
    type: 'string',
    title: 'Product SKU',
    description:
      'The product SKU should be used to uniquely identify which product to order for this line item.',
  },
  {
    name: 'items[].shortDescription',
    type: 'string',
    title: 'Line Item Short Description',
    description:
      'This is the line item description for display on order emails, invoices, packing slips, etc.',
  },
  {
    name: 'items[].qty',
    isRequired: true,
    type: 'string',
    title: 'Quantity',
    description:
      'Quantity for the line item.<br/><strong>Example:</strong> <code>"2.00"</code>',
  },
  {
    name: 'items[].requiresShipping',
    type: 'boolean',
    defaultValue: 'true',
    defaultValueType: 'bool',
    title: 'Requires Shipping',
    description: 'Does this line item require shipping to the customer?',
  },
  {
    name: 'items[].useFixedPrice',
    type: 'bool',
    title: 'Use Fixed Price',
    defaultValue: 'false',
    defaultValueType: 'bool',
    description: 'Should a fixed price be used for this item?',
  },
  {
    name: 'items[].fixedPrice',
    type: 'string',
    title: 'Fixed Price',
    description:
      'Fixed price (unit price) to use for this line item. This price should override any pricing that would otherwise be calculated by the handling system.<br/><strong>Example:</strong> <code>"30.2000"</code>',
  },
  {
    name: 'items[].subscription',
    type: 'object',
    title: 'Subscription',
    description:
      'Details about the Subscribe Pro subscription record which is being ordered with this line item.',
  },
  {
    name: 'items[].subscription.id',
    type: 'string',
    title: 'Subscription ID',
    description:
      'Subscribe Pro subscription ID of the subscription item which is associated with this line item.',
  },
  {
    name: 'items[].subscription.interval',
    type: 'string',
    title: 'Current Subscription Frequency Interval',
    description:
      'The current frequency interval set on the subscription item at the time order was requested.',
  },
  {
    name: 'items[].subscription.scheduleName',
    type: 'string',
    title: 'Current Subscription Schedule Name',
    description:
      'The current schedule name of the schedule set on the subscription item at the time order was requested.',
  },
  {
    name: 'items[].subscription.reorderOrdinal',
    type: 'string',
    title: 'Current Subscription Reorder Ordinal',
    description:
      'The current order will represent the Nth order against this subscription item.',
  },
  {
    name: 'items[].subscription.nextOrderDate',
    type: 'string',
    title: 'Subscription Next Order Date',
    description:
      'The expected next order date for this subscription item (assuming the current order is successful). ',
  },
  {
    name: 'items[].subscription.userDefinedFields',
    type: 'array',
    title: 'Subscription User Defined Fields',
    description:
      'An array of user defined fields which are currently stored on the subscription item.',
  },
  {
    name: 'items[].subscription.magento2ProductOptions',
    type: 'array',
    title: 'Magento 2 Product Options',
    description:
      'In the case that the order callback is used with a Magento 2 integration, this field contains an array of Magento 2 product options data which is stored on the subscription item.',
  },
];

const OrderCallbackSchema = () => {
  return <DataModelTable properties={schemaJson} />;
};

export default OrderCallbackSchema;
