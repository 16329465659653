import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Wrapper = styled.div``;

const Card = ({ children, to }) => (
  <Wrapper className="sp-card">
    {to ? <Link to={to}>{children}</Link> : <>{ children }</>}
  </Wrapper>
);

export default Card;
