import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import _ from 'lodash';

import Endpoint from './Endpoint';

const API_SPEC_QUERY = graphql`
  query SpecQuery {
    allOpenApiSpecDefinition {
      edges {
        node {
          name
          properties {
            name
            type
            description
            format
          }
        }
      }
    }
    allOpenApiSpecPath {
      edges {
        node {
          id
          fullPath
          name
          verb
          summary
          description
          parameters {
            name
            in
            description
            required
            type
            schema {
              _ref
            }
          }
          tag
          childrenOpenApiSpecResponse {
            id
            statusCode
            description
            childrenOpenApiSpecDefinition {
              name
              properties {
                name
                type
                description
                format
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * Parse defintions into map
 * This is a workaround until openapi source can be modified to provide the defintion in children
 */
const buildDefintionMap = defintionEdges =>
  _.keyBy(
    defintionEdges.map(edge => edge.node),
    node => '#/definitions/' + node.name
  );

const buildExampleResponse = pathSpecResponse => {
  const example = {};

  if (_.has(pathSpecResponse, 'definition')) {
    pathSpecResponse.definition.properties.forEach((property, index) => {
      example[property.name] = 'string';
    });
  }

  return example;
};

const buildExampleRequest = pathSpec => {
  const bodyParams = pathSpec.parameters.filter(
    parameter => parameter.in === 'body'
  );
  const bodyParamsWithProps = bodyParams.filter(parameter =>
    _.has(parameter, 'definition.properties[0]')
  );
  // Get properties from first bodyParamWithProps
  const properties =
    bodyParamsWithProps.length > 0
      ? bodyParamsWithProps[0].definition.properties
      : [];

  // Build example from properties
  const example = {};
  properties.forEach(property => {
    example[property.name] = 'string';
  });

  return example;
};

const addParameterDefinitions = (pathSpec, defMap) => {
  pathSpec.parameters.forEach((parameter, index) => {
    const ref = _.get(parameter, 'schema._ref', {});
    parameter.definition = defMap[ref];
  });
};

const addResponseDefinitions = pathSpec => {
  pathSpec.childrenOpenApiSpecResponse.forEach(pathSpecResponse => {
    if (pathSpecResponse.childrenOpenApiSpecDefinition.length) {
      // Assume only 1 definition is child
      pathSpecResponse.definition =
        pathSpecResponse.childrenOpenApiSpecDefinition[0];
    }
  });
};

const augmentPathSpec = (pathSpec, defMap) => {
  // Add definitions to parameters where appropriate
  addParameterDefinitions(pathSpec, defMap);
  // Add response definitions
  addResponseDefinitions(pathSpec);
  // Map successResponse
  pathSpec.successResponse = pathSpec.childrenOpenApiSpecResponse[0];
  // Set response example
  pathSpec.successResponse.example = buildExampleResponse(
    pathSpec.successResponse
  );
  // Set request example
  pathSpec.example = buildExampleRequest(pathSpec);
};

class StaticEndpoint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <StaticQuery
        query={API_SPEC_QUERY}
        render={data => {
          // Parse defintions into map
          const defMap = buildDefintionMap(data.allOpenApiSpecDefinition.edges);

          // Parse paths into map
          const allPaths = data.allOpenApiSpecPath.edges.map(edge => edge.node);
          const pathMap = _.keyBy(
            allPaths,
            path => path.name + '.' + path.verb
          );

          if (_.has(pathMap, this.props.path)) {
            // Find spec and augment
            const pathSpec = pathMap[this.props.path];

            // Add extra properties
            augmentPathSpec(pathSpec, defMap);

            return (
              <Endpoint
                pathName={this.props.path}
                pathSpec={pathSpec}
                hideTitle={_.has(this.props, 'hide-title')}
              />
            );
          } else {
            return <></>;
          }
        }}
      />
    );
  }
}

export default StaticEndpoint;
