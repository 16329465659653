import React from 'react';
import { graphql } from 'gatsby';
import rehypeReact from 'rehype-react';

import Seo from '../layout/SEO';
import H1 from '../components/heading/H1';
import H2 from '../components/heading/H2';
import H3 from '../components/heading/H3';
import H4 from '../components/heading/H4';
import H5 from '../components/heading/H5';
import H6 from '../components/heading/H6';
import StaticEndpoint from '../components/api/StaticEndpoint';
import Title from '../components/api/Title';
import WidgetConfigDocs from '../components/my-subscriptions-widget/WidgetConfigDocs';
import DefaultConfig from '../components/my-subscriptions-widget/DefaultConfig';
import Card from '../components/cards/Card';
import Cards from '../components/cards/Cards';
import Table from '../components/Table';
import MarkdownLink from '../components/MarkdownLink';
import FontAwesomeIcon from '../components/FontAwesomeIcon';
import DocsImage from '../components/image/DocsImage';
import WebhookEventSchema from '../components/webhooks/WebhookEventSchema';
import HostedSubscriptionsVersion from '../components/my-subscriptions-widget/HostedSubscriptionsVersion';
import MetadataFieldsSchema from '../components/data-models/MetadataFieldsSchema';
import OrderDetailsSchema from '../components/data-models/OrderDetailsSchema';
import OrderCallbackSchema from '../components/data-models/OrderCallbackSchema';

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    a: MarkdownLink,
    fa: FontAwesomeIcon,
    h1: H1,
    h2: H2,
    h3: H3,
    h4: H4,
    h5: H5,
    h6: H6,
    card: Card,
    cards: Cards,
    'docs-image': DocsImage,
    table: Table,
    'api-endpoint': StaticEndpoint,
    'api-endpoint-title': Title,
    'my-subscriptions-widget-config-docs': WidgetConfigDocs,
    'my-subscriptions-widget-default-config': DefaultConfig,
    'webhook-event-schema': WebhookEventSchema,
    'metadata-fields-schema': MetadataFieldsSchema,
    'order-details-schema': OrderDetailsSchema,
    'order-callback-schema': OrderCallbackSchema,
    'hosted-subscriptions-version': HostedSubscriptionsVersion,
  },
}).Compiler;

const ContentPage = ({ data, pageContext }) => {
  const page = data.markdownRemark;
  const description = page.frontmatter.description
    ? page.frontmatter.description
    : page.excerpt;

  return (
    <>
      <Seo
        title={page.frontmatter.title}
        description={description}
        ogImageUrl={`${data.site.siteMetadata.siteUrl}/docs-subscribepro-homepage-2.png`}
        pageUrl={data.site.siteMetadata.siteUrl.slice(0, -1) + pageContext.slug}
        keywords={[page.frontmatter.title]}
      />

      <>{renderAst(page.htmlAst)}</>
    </>
  );
};

export default ContentPage;

export const pageQuery = graphql`
  query ContentPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      htmlAst
      frontmatter {
        title
        description
        showLeftNav
        showRightNav
      }
      tableOfContents(maxDepth: 2)
    }
  }
`;
