import React from 'react';
import { FontAwesomeIcon as FontAwesomeIconBase } from '@fortawesome/react-fontawesome';

/**
 * If first 2 chars are 'fa' and 4th char is '-', its a prefix
 * @param {string} iconName
 */
const nameHasPrefix = iconName =>
  iconName &&
  typeof iconName === 'string' &&
  iconName.startsWith('fa') &&
  iconName.charAt(3) === '-';

const getPrefixFromName = iconName =>
  nameHasPrefix(iconName) ? iconName.substring(0, 3) : 'fas';

const trimPrefixFromName = iconName =>
  nameHasPrefix(iconName) ? iconName.substring(4) : iconName;

const FontAwesomeIcon = props => {
  var { icon, children, ...elemProps } = props;
  var iconProp = [getPrefixFromName(icon), trimPrefixFromName(icon)];

  return (
    <FontAwesomeIconBase icon={iconProp} {...elemProps}>
      {children}
    </FontAwesomeIconBase>
  );
};

export default FontAwesomeIcon;
