import React from 'react';
import DataModelTable from '../DataModelTable';

const schemaJson = [
  {
    name: 'customerId',
    type: 'string',
    title: 'Subscribe Pro Customer ID',
  },
  {
    name: 'customerEmail',
    type: 'string',
    title: 'Customer Email',
  },
  {
    name: 'platformCustomerId',
    type: 'string',
    title: 'eCommerce Platform Customer ID',
  },
  {
    name: 'platformOrderId',
    type: 'string',
    title: 'eCommerce Platform Order ID',
  },
  {
    name: 'orderNumber',
    type: 'string',
    isRequired: true,
    title: 'Order Number',
    description:
      'Unique alpha-numeric order number that identifies this order.',
  },
  {
    name: 'salesOrderToken',
    type: 'string',
    title: 'Sales Order Token',
    description:
      'Return the Subscribe Pro order token when passing order details. This is a unique token which was provided by Subscribe Pro when placing an order.',
  },
  {
    name: 'orderStatus',
    type: 'string',
    isRequired: true,
    title: 'Order Status',
  },
  {
    name: 'orderState',
    type: 'string',
    title: 'Order State',
  },
  {
    name: 'orderDateTime',
    type: 'string',
    isRequired: true,
    title: 'Order Date / Time',
    description:
      'Date / time when the order was placed.<br/><strong>Example:</strong> <code>"2021-04-01T17:43:00Z"</code>',
  },
  {
    name: 'currency',
    type: 'string',
    title: 'Currency Code',
    description: '3 Digit ISO Currency Code',
  },
  {
    name: 'discountTotal',
    type: 'string',
    title: 'Discount Total',
    description:
      'Discount total for the order.<br/><strong>Example:</strong> <code>"2.0000"</code>',
  },
  {
    name: 'shippingTotal',
    type: 'string',
    title: 'Shipping Total',
    description:
      'Shipping total for the order.<br/><strong>Example:</strong> <code>"1.6000"</code>',
  },
  {
    name: 'taxTotal',
    type: 'string',
    title: 'Tax Total',
    description:
      'Tax total for the order.<br/><strong>Example:</strong> <code>"0.6000"</code>',
  },
  {
    name: 'total',
    type: 'string',
    isRequired: true,
    title: 'Order Total',
    description:
      'Grand total for the order (include shipping, tax and discount).<br/><strong>Example:</strong> <code>"30.8000"</code>',
  },
  {
    name: 'billingAddress',
    type: 'object',
    isRequired: true,
    title: 'Billing Address',
    description: "Customer's billing address associated with the order.",
  },
  {
    name: 'billingAddress.firstName',
    type: 'string',
    isRequired: true,
    title: 'First Name',
  },
  {
    name: 'billingAddress.lastName',
    type: 'string',
    isRequired: true,
    title: 'Last Name',
  },
  {
    name: 'billingAddress.company',
    type: 'string',
    title: 'Company',
    description: 'Company name associated with the address',
  },
  {
    name: 'billingAddress.street1',
    type: 'string',
    title: 'Street Address Line 1',
  },
  {
    name: 'billingAddress.street2',
    type: 'string',
    title: 'Street Address Line 2',
  },
  {
    name: 'billingAddress.street3',
    type: 'string',
    title: 'Street Address Line 3',
  },
  {
    name: 'billingAddress.city',
    type: 'string',
    title: 'City',
  },
  {
    name: 'billingAddress.region',
    type: 'string',
    title: 'Region',
    description: 'Region, state or province',
  },
  {
    name: 'billingAddress.postcode',
    type: 'string',
    title: 'Postcode',
  },
  {
    name: 'billingAddress.country',
    type: 'string',
    title: 'Country',
    description: 'Country name, code or abbreviation',
  },
  {
    name: 'billingAddress.countryName',
    type: 'string',
    title: 'Country Name',
  },
  {
    name: 'billingAddress.phone',
    type: 'string',
    title: 'Phone',
    description: 'The phone number associated with the address.',
  },
  {
    name: 'shippingAddress',
    type: 'object',
    title: 'Shipping Address',
    description: "Customer's shipping address associated with the order.",
  },
  {
    name: 'shippingAddress.firstName',
    type: 'string',
    title: 'First Name',
  },
  {
    name: 'shippingAddress.lastName',
    type: 'string',
    title: 'Last Name',
  },
  {
    name: 'shippingAddress.company',
    type: 'string',
    title: 'Company',
    description: 'Company name associated with the address',
  },
  {
    name: 'shippingAddress.street1',
    type: 'string',
    title: 'Street Address Line 1',
  },
  {
    name: 'shippingAddress.street2',
    type: 'string',
    title: 'Street Address Line 2',
  },
  {
    name: 'shippingAddress.street3',
    type: 'string',
    title: 'Street Address Line 3',
  },
  {
    name: 'shippingAddress.city',
    type: 'string',
    title: 'City',
  },
  {
    name: 'shippingAddress.region',
    type: 'string',
    title: 'Region',
    description: 'Region, state or province',
  },
  {
    name: 'shippingAddress.postcode',
    type: 'string',
    title: 'Postcode',
  },
  {
    name: 'shippingAddress.country',
    type: 'string',
    title: 'Country',
    description: 'Country name, code or abbreviation',
  },
  {
    name: 'shippingAddress.countryName',
    type: 'string',
    title: 'Country Name',
  },
  {
    name: 'shippingAddress.phone',
    type: 'string',
    title: 'Phone',
    description: 'The phone number associated with the address.',
  },
  {
    name: 'items',
    isRequired: true,
    type: 'array',
    title: 'Items',
    description: 'Array of order line items for this order.',
  },
  {
    name: 'items[].platformOrderItemId',
    isRequired: true,
    type: 'string',
    title: 'eCommerce Platform Order Item Id',
  },
  {
    name: 'items[].productSku',
    isRequired: true,
    type: 'string',
    title: 'Product SKU',
  },
  {
    name: 'items[].productName',
    type: 'string',
    title: 'Product Name',
  },
  {
    name: 'items[].shortDescription',
    type: 'string',
    title: 'Line Item Short Description',
  },
  {
    name: 'items[].qty',
    isRequired: true,
    type: 'string',
    title: 'Quantity',
    description:
      'Quantity for the line item.<br/><strong>Example:</strong> <code>"2.00"</code>',
  },
  {
    name: 'items[].unitPrice',
    type: 'string',
    title: 'Unit Price',
    description:
      'Unit price for the line item.<br/><strong>Example:</strong> <code>"1.6000"</code>',
  },
  {
    name: 'items[].discountTotal',
    type: 'string',
    title: 'Discount Total',
    description:
      'Discount total for the line item.<br/><strong>Example:</strong> <code>"0.6000"</code>',
  },
  {
    name: 'items[].shippingTotal',
    type: 'string',
    title: 'Shipping Total',
    description:
      'Shipping total for the line item.<br/><strong>Example:</strong> <code>"1.2000"</code>',
  },
  {
    name: 'items[].lineTotal',
    isRequired: true,
    type: 'string',
    title: 'Line Total',
    description:
      'Line total for the line item (include shipping and discount).<br/><strong>Example:</strong> <code>"3.2000"</code>',
  },
  {
    name: 'items[].requiresShipping',
    type: 'boolean',
    title: 'Requires Shipping',
    description: 'Does this line item require shipping to the customer?',
  },
  {
    name: 'items[].subscriptionId',
    type: 'string',
    title: 'Subscription ID',
    description: 'Subscribe Pro subscription ID of the subscription item which is associated with this line item.',
  },
];

const OrderDetailsSchema = () => {
  return <DataModelTable properties={schemaJson} />;
};

export default OrderDetailsSchema;
