import React from 'react';
import styled from 'styled-components';
import Prism from 'prismjs';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-bash';
import 'prismjs/plugins/normalize-whitespace/prism-normalize-whitespace';

const Wrapper = styled.div`
  .title {
    margin-bottom: 1.5rem;
    .status,
    .description {
      font-size: 1.1rem;
    }
  }
`;

const Request = ({ verb, fullPath, exampleBody }) => {
  const hasBody =
    typeof exampleBody === 'object' && Object.keys(exampleBody).length > 0;

  // Build bash command
  var bashCmdAll = `
curl -X ${verb.toUpperCase()} https://api.pilon.io${fullPath} \\
     -H "Authorization: Bearer ACCESS_TOKEN_GOES_HERE" \\
     -H "Content-Type: application/json"`;
  const bashCmdAddBody = ` \\
     -d $'`;
  const bashCmd = bashCmdAll.trimStart() + (hasBody ? bashCmdAddBody : '');

  // Parse bash into html
  var bashCmdHtml = Prism.highlight(bashCmd, Prism.languages.bash, 'bash');

  // Add body
  if (hasBody) {
    // Turn body JSON into string
    // JSON.stringify with indent
    // Add extra indent
    // But trim indent from first line
    const nw = Prism.plugins.NormalizeWhitespace;
    const bodyJsonString = nw
      .normalize(JSON.stringify(exampleBody, null, 2), {
        // Extra settings
        'left-trim': true,
        indent: 2,
      })
      .trimStart();

    // Parse body JSON into html
    const bodyJsonHtml = Prism.highlight(
      bodyJsonString,
      Prism.languages.json,
      'json'
    );

    // Append JSON to bash command
    bashCmdHtml = bashCmdHtml + bodyJsonHtml + "'";
  }

  return (
    <Wrapper className="response">
      <pre className="language-bash">
        <code
          dangerouslySetInnerHTML={{
            __html: bashCmdHtml,
          }}
        />
      </pre>
    </Wrapper>
  );
};

export default Request;
