import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

const Div = styled.div`
  overflow-x: auto;
`;

const Table = props => {
  var { className, ...elemProps } = props;

  return (
    <Div>
      <table className={classNames(className, 'table')} {...elemProps}>
        {props.children}
      </table>
    </Div>
  );
};

export default Table;
