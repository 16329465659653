import React from 'react';
import classNames from 'classnames';

const H5 = props => {
  var { className, ...elemProps } = props;

  return (
    <h5 className={classNames(className, 'title', 'is-5')} {...elemProps}>
      {props.children}
    </h5>
  );
};

export default H5;
